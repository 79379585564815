import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import ContactForm from '../../../components/molecules/forms/contact-us/contact-us';
import { AppLayout } from '../../../components';

export const HomeScreen = () => {
  const Logo = require('../../../assets/images/logo_white.png');
  const Runner = require('../../../assets/images/runner.png');
  const Tennis = require('../../../assets/images/tennis.png');

  const ScrollToTop = () => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    const openWhatsApp = () => {
      const phoneNumber = '+27691302407';
      window.open(`https://wa.me/${phoneNumber}`, '_blank');
    };

    return (
      <div className="fixed md:bottom-10 bottom-10 right-5 md:right-20 flex gap-2 z-50">
        <div onClick={scrollToTop}>
          <FontAwesomeIcon
            className="bg-black text-white rounded-full p-3 hover:bg-pivotal-blue hover:cursor-pointer shadow-md"
            icon={faArrowUp}
          />
        </div>

        <div onClick={openWhatsApp}>
          <FontAwesomeIcon
            className="bg-green-500 text-white rounded-full p-3 hover:bg-green-600 hover:cursor-pointer shadow-md"
            icon={faWhatsapp}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease-in-out', // Easing function
      once: false, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <AppLayout toggle="home">
      <ScrollToTop />

      {/* Header Section */}
      <header className="bg-black text-white text-center py-28" data-aos="fade-in">
        <div className="container mx-auto">
          <div className="mb-6">
            <img src={Logo} alt="Logo" className="mx-auto w-28" />
          </div>
          <p className="text-3xl font-semibold">
            Source From Sport
          </p>
        </div>
      </header>
      <section className="container mx-auto text-center py-28" id="about" data-aos="fade-up">
        <p className="md:text-4xl text-2xl font-semibold">
          At Source From Sport we bridge the gap between the realm of sports and the corporate
          arena, and unlock a world of unparalleled potential.
        </p>
      </section>

      {/* For Athletes and For Businesses Section */}
      <section className="container mx-auto px-4 md:grid md:grid-cols-2" id="athlete">
        {/* For Athletes */}
        <div className="md:flex md:flex-row md:col-span-2">
          <div className='mr-8 mb-8 w-full' data-aos="fade-down">
            <h2 className="text-pivotal-blue text-2xl font-bold mb-4">FOR ATHLETES</h2>
            <p className="mb-4 text-pivotal-blue">
              We bridge the gap between the field and the corporate world. We help retiring athletes utilise the numerous skills they have gained in the sporting world to succeed in a new career.
            </p>
            <p>
              Our platform is dedicated to helping athletes
              like you harness your competitive spirit,
              discipline, and determination to succeed in
              the corporate world.</p>
            <p className='font-bold'>Expert guidance and career planning</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Expert guidance and career planning</li>
              <li>Networking opportunities</li>
              <li>Transfer your skills into the corporate arena</li>
              <li>Industry partnerships</li>
              <li>Skill development</li>
            </ul>
          </div>
          <div className='col-span-1 md:w-[1320px] w-full' data-aos="fade-in" data-aos-delay="300">
            <img src={Runner} alt="Athletes" className="object-cover w-full" />
          </div>
        </div>

        {/* For Businesses */}
        <div className="md:flex md:flex-row md:col-span-2" id="business" data-aos="fade-in">
          <div className='col-span-1 md:w-[1320px] w-full' data-aos="fade-in" data-aos-delay="300">
            <img src={Tennis} alt="Business" className="object-cover w-full" />
          </div>
          <div className='md:pl-8 mt-8 w-full col-span-1' data-aos="fade-down">
            <h2 className="text-pivotal-blue text-2xl font-bold mb-4">FOR BUSINESSES</h2>
            <p className="mb-4 text-pivotal-blue">
              We bridge the gap between the sports arena
              and the boardroom, presenting your
              company with a rare opportunity to infuse
              athlete excellence into your workforce.
            </p>
            <p>
              We provide a seamless platform for you to
              discover, connect with, and hire retiring
              athletes who are ready to bring their winning
              mindset to your team.</p>
            <p className='font-bold'>Why hire athletes?</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Proven performance under pressure</li>
              <li>Strong work ethic</li>
              <li>Resilience and problem-solving abilities</li>
              <li>Unique perspectives</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Our Approach Section */}
      <section className="bg-pivotal-blue text-white py-16 mt-16" data-aos="fade-up">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-12 text-center">OUR APPROACH</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div data-aos="zoom-in">
              <h3 className="text-2xl font-bold mb-2">01.</h3>
              <p>We collaborate closely with athletes to understand their long-term goals and aspirations after retirement. Together, we outline a clear career  path and ensure all necessary documentation is organised for any  applications.</p>
            </div>
            <div data-aos="zoom-in" data-aos-delay="100">
              <h3 className="text-2xl font-bold mb-2">02.</h3>
              <p>Leveraging our extensive network of businesses, we identify and connect  athletes with organisations and roles that align with their ambitions.</p>
            </div>
            <div data-aos="zoom-in" data-aos-delay="200">
              <h3 className="text-2xl font-bold mb-2">03.</h3>
              <p>In preparation for interviews we offer tailored guidance and coaching.  We also work directly with businesses to gather feedback and manage the  next steps, ensuring a smooth transition throughout the hiring process.</p>
            </div>
            <div data-aos="zoom-in" data-aos-delay="300">
              <h3 className="text-2xl font-bold mb-2">04.</h3>
              <p>Our team handles all negotiations on behalf of the athlete. Beyond  successful placement, we offer continuous support to ensure a seamless  integration into their new role. </p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Us Section */}
      <section className="container mx-auto px-4 py-16" id="contact" data-aos="fade-up">
        <div className="mb-8">
          <h2 className="text-3xl font-bold">CONTACT US TODAY</h2>
        </div>
        <div>
          <ContactForm />
        </div>
      </section>
    </AppLayout>

  );
};