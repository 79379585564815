import { Link } from 'react-router-dom';

export const Footer = () => {
  const InstagramLogo = require('../../../assets/images/instagram.png');
  const facebookLogo = require('../../../assets/images/facebook.png');
  const TwitterLogo = require('../../../assets/images/twitter.png');
  const TikTokLogo = require('../../../assets/images/tiktok.png');
  const Logo = require('../../../assets/images/logo_white.png');
  const linkStyle = 'hover:cursor-pointer';

  const ButtonMailto = ({ mailto, label }) => {
    return (
      <Link
        to="#"
        onClick={(e) => {
          window.location.href = mailto;
          e.preventDefault();
        }}
      >
        <p className="text-white hover:text-blue-400">{label}</p>
      </Link>
    );
  };

  const _home = () => {
    document.getElementById('home').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const _about = () => {
    document.getElementById('about').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const _athlete = () => {
    document.getElementById('athlete').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const _business = () => {
    document.getElementById('business').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const _contact = () => {
    document.getElementById('contact').scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div className="text-white bg-pivotal-blue">
        <div className="md:flex justify-between gap-24 py-12 container">
          <div onClick={_home} className='md:mb-0 mb-4'>
            <img src={Logo} alt="pivotal" className="hover:cursor-pointer w-14" />
          </div>
          {/* <div>
            <h1 className="font-bold text-xl text-white">Services</h1>
            <ul className="ml-[-30px]">
              <li>For Athletes</li>
              <li>For Businesses</li>
            </ul>
          </div> */}
          <div>
            <h1 className="font-bold text-xl text-white">Services</h1>
            <ul className="ml-[-30px]">
              <li className={linkStyle} onClick={_about}>
                <span>About</span>
              </li>
              <li className={linkStyle} onClick={_athlete}>
                <span>For Athletes</span>
              </li>
              <li className={linkStyle} onClick={_business}>
                <span>For Buinesses</span>
              </li>
              <li className={linkStyle} onClick={_contact}>
                <span>Contact</span>
              </li>
            </ul>
          </div>

          {/* <div>
            <h1 className="font-bold text-xl text-white">Support</h1>
            <ul className="ml-[-30px]">
              <li className="overflow-hidden">
                <ButtonMailto label="info@business.co.za" mailto="mailto:business.co.za" />
              </li>
            </ul>
          </div> */}
          <div>
            <h1 className="font-bold text-xl text-white">Social Media</h1>
            <ul className="ml-[-30px] inline-flex space-x-2">
              <li>
                <a href="https://www.facebook.com/profile.php?id=100090099461564">
                  <img src={facebookLogo} alt="Facebook" className="w-8 hover:cursor-pointer" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/xencode__/">
                  <img src={InstagramLogo} alt="Instagram" className="w-8 hover:cursor-pointer" />
                </a>
              </li>
              <li>
                <img
                  src={TwitterLogo}
                  alt="Twitter"
                  className="w-8 hover:cursor-pointer opacity-40"
                />
              </li>
              <li>
                <img
                  src={TikTokLogo}
                  alt="TikTok"
                  className="w-8 hover:cursor-pointer opacity-40"
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="flex justify-between text-xs gap-4 container">
          <p className="text-white">© COPYRIGHT 2024. ALL RIGHTS RESERVED</p>
          <p className="text-white">WEBSITE DESIGN BY XenCode </p>
        </div>
      </div>
    </>
  );
};
