import React from 'react';

import { Nav } from '../../molecules';
import { Footer } from '../../atoms/footer';

interface AppProps {
  children: React.ReactNode;
  toggle?: string;
  style?: React.CSSProperties;
  className?: string; // Add className prop
}

export const AppLayout: React.FC<AppProps> = ({ children, toggle, style, className }) => {
  return (
    <Nav toggle={toggle}>
      <div>{children}</div>
      {<Footer />}
    </Nav>
  );
};
