/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactElement, ReactNode, useState } from 'react';

export const Nav = ({ children }: { children: ReactNode; toggle: string }): ReactElement => {
  const [navbar, setNavbar] = useState(false);
  const Logo = require('../../../assets/images/logo.png');
  const navStyle = 'text-pivotal-nav-text hover:text-opacity-70 hover:cursor-pointer';

  // Scroll to specific section
  const scrollToSection = (sectionId: string) => {
    document.getElementById(sectionId)?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const toggleNavbar = () => setNavbar(!navbar);

  return (
    <div>
      <nav className="w-full fixed z-50 bg-white">
        <div className="flex justify-between container">
          {/* Logo Section */}
          <div className='self-center'>
            <a onClick={() => scrollToSection('home')}>
              <img src={Logo} alt="Logo" className="hover:cursor-pointer w-8 md:w-8 py-2" />
            </a>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden self-center">
            <button
              className="p-2 text-gray-700 rounded-md outline-none focus:border-pivotal-blue"
              onClick={toggleNavbar}
            >
              {navbar ? (
                <svg className="w-6 h-6" viewBox="0 0 20 20" fill="black">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  className="w-6 h-6"
                  fill="black"
                  viewBox="0 0 24 24"
                  stroke="black"
                  strokeWidth={2}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
          </div>

          {/* Navigation Links */}
          <div className={`md:flex md:items-center ${navbar ? 'block' : 'hidden'} w-full md:w-auto self-center mt-3`}>
            <ul className="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-6 self-center">
              <li onClick={() => scrollToSection('about')} className={navStyle}>
                <a>ABOUT US</a>
              </li>
              <li onClick={() => scrollToSection('athlete')} className={navStyle}>
                <a>FOR ATHLETES</a>
              </li>
              <li onClick={() => scrollToSection('business')} className={navStyle}>
                <a>FOR BUSINESSES</a>
              </li>
              <li
                onClick={() => scrollToSection('contact')}
                className="hover:cursor-pointer hover:text-opacity-70 md:text-white md:bg-pivotal-blue md:p-2 md:px-4"
              >
                <a>CONTACT</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="pt-16">{children}</div>
    </div>
  );
};
