import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';
import config from '../../../../config';

import 'react-toastify/dist/ReactToastify.css';

export const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const toastifySuccess = () => {
    toast('Message sent! We will get back to you shortly.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast',
    });
  };

  const onSubmit = async (data) => {
    const { name, email, phone, message } = data;
    setIsLoading(true);

    try {
      const templateParams = {
        name,
        email,
        phone,
        message,
      };

      await emailjs.send(config.serviceId, config.templateId, templateParams, config.userId);

      reset();
      toastifySuccess();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const inputStyle =
    'p-3 mb-3 text-black w-full bg-pivotal-getintouch-button border-none outline-none';
  const labelStyle = 'text-pivotal-text text-left';
  const errorStyle = 'text-xs text-red-600';

  return (
    <div>
      <form id="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="pb-3 grid w-full">
          <label className={labelStyle}>Name</label>
          <input
            type="text"
            name="name"
            placeholder="Name"
            {...register('name', {
              required: { value: true, message: 'Please enter your name' },
              maxLength: {
                value: 30,
                message: 'Please use 30 characters or less',
              },
            })}
            className={inputStyle}
          />
          {errors.name && <p className={errorStyle}>{errors.name.message}</p>}
        </div>
        <div className="grid w-full">
          <label className={labelStyle}>Email</label>
          <input
            type="email"
            name="email"
            placeholder="Email"
            {...register('email', {
              required: true,
              pattern: /^[a-zA-Z0-9.!#$%&’+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/,
            })}
            className={inputStyle}
          />
          {errors.email && <p className={errorStyle}>Please enter a valid email address</p>}
        </div>
        <div className="grid w-full">
          <label className={labelStyle}>Phone Number</label>
          <input
            type="tel"
            name="phone"
            placeholder="Phone Number"
            {...register('phone', {
              required: { value: true, message: 'Please enter your phone number' },
              pattern: {
                value: /^\d{10}$/,
                message: 'Please enter a valid phone number',
              },
            })}
            className={inputStyle}
          />
          {errors.phone && <p className={errorStyle}>{errors.phone.message}</p>}
        </div>
        <div className="w-full grid">
          <label className={labelStyle}>Message</label>
          <textarea
            rows={3}
            name="message"
            placeholder="Message"
            {...register('message', {
              required: true,
            })}
            className={inputStyle}
          />
          {errors.message && <span className={errorStyle}>Please enter a message</span>}
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className={`bg-pivotal-blue text-white my-2 py-3 px-16 hover:opacity-70 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
        </div>
        <div className="text-white text-center mt-4">
          <ToastContainer />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
